import Layout from "../../components/layout"
import Hero from "../../components/hero"
import React, { Component } from "react"
import Reviews from "../../components/reviews"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../../components/seo"
import OptInForm from '../../components/optInForm'
import GoogleReviewCarousel from "../../components/google-review-carousel"
import * as ServiceAreasStyles from "../../styles/service-areas.module.scss"

const BucktownPage = () => {
    const data = useStaticQuery(graphql`
    query BucktownPageQuery {
      buckTownImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/bucktown-chicago" }
      ) {
        secure_url
      }
      cabinetPaintingImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/cabinet-painting-pivot-painters" }
      ) {
        secure_url
      }
      interiorPaintingImage: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/interior-painting-pivot-painters" }
      ) {
        secure_url
      }
    }
  `)
    return (
        <Layout>
            <SEO title="Bucktown Painters | Painting Contractors in Chicago Bucktown" description="Pivot Painters is one best painting companies servicing Bucktown Chicago, IL. Dedicated to providing quality painting and customer service to our customers." />
            <Hero name="Bucktown Painters" src={data.buckTownImage.secure_url} />
            <div className={ServiceAreasStyles.serviceAreaContainer}>
                <h2>#1 Choice for Painting in Bucktown, IL</h2>
                <div className={ServiceAreasStyles.serviceAreaIntroSubContainer}>
                    <p>
                        Pivot Painters - The top painting service in Bucktown, Chicago is Your Trusted House Painters, we offer competitive, expert, and competitive painting services. This distinguishes us from other painters. <br /><br />Spend less time and money while giving your home, place of business, or any other space a fresh new look! We use the most up-to-date painting methods and premium paints, and we treat every residential and business area as though it were a work of art.<br /><br /> Additionally, we offer advice on the latest color trends, the best paint to use, and other painting tips that will work with your house. We are the reliable option for interior painting in Bucktown, Chicago!
                    </p>
                    {/*<a href="tel:3128549180">‪(312) 854-9180‬</a>*/}
                </div>
                <h2>Professional Painting Services in Bucktown</h2>
                <p className={ServiceAreasStyles.serviceAreaServicesSubHeader}>If you need a professional painter near you, turn to Pivot Painters.<br /> Proudly serving the zip code 60614 in Bucktown, IL</p>
                <div className={ServiceAreasStyles.serviceAreaServicesSubContainer}>
                    <a href="/interior-painting-chicago">
                        <img src={data.interiorPaintingImage.secure_url} alt="" />
                        <p className={ServiceAreasStyles.serviceAreaServicesTitle}>Interior Painting</p>
                        <p className={ServiceAreasStyles.serviceAreaServicesDescription}>Pivot Painters provides quality interior painting services, creating a seamless experience for all of our clients.</p>
                    </a>
                    <a href="/cabinet-refinishing-chicago">
                        <img src={data.cabinetPaintingImage.secure_url} alt="" />
                        <p className={ServiceAreasStyles.serviceAreaServicesTitle}>Cabinet Painting</p>
                        <p className={ServiceAreasStyles.serviceAreaServicesDescription}>Pivot Painters is a leader in cabient refinishing, we use high quality wood coatings imported from Italy for all projects.</p>
                    </a>
                </div>
                <h2>Why Choose Pivot Painters?</h2>
                <div className={ServiceAreasStyles.serviceAreaWhyUsContainer}>
                    <ul>
                        <li><strong>Reliable - </strong> No more chasing Contractors. We understand how frustrating it can be working with unprofessional painters, and that's why when when we say we are going to do something it gets done. Period.</li><br></br>
                        <li><strong>Insured - </strong> Having painters into your home and business can be nerve racking but you don't have to worry about that with Pivot Painters. We are fully insured for your peace of mind.</li><br></br>
                        <li><strong>Quality - </strong> Not only do we use leading painting techniques but we also use the highest quality paints and tools to ensure your project comes out perfect.</li><br></br>
                        <li><strong>Communication - </strong> Using cutting edge tech and good ol' empathy we create a seamless experience for our clients to get their projects completed the way they envisioned.</li><br></br>
                    </ul>
                </div>
                <h2>What Past Customers in Bucktown Are Saying</h2>
                <GoogleReviewCarousel />
                <OptInForm></OptInForm>
                <div className={ServiceAreasStyles.resources}>
                    <p>Resources In Bucktown, IL</p>
                    <a href="https://www.wickerparkbucktown.com/">Bucktown Chamber of Commerce</a>
                    <a href="https://www.bucktown.org/">Bucktown Community Organization</a>
                </div>
            </div>
        </Layout>
    )
}

export default BucktownPage
